import React from "react";
import { Flex } from "@src/components/Boxes";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "@src/components/SEO";
import Translate from "@src/components/translation/Translate";
import GatsbyImageWrapper from "@src/components/GatsbyImageWrapper";

const Header = styled("h1")`
  font-size: 48px;
  font-weight: 800;
  line-height: 52px;
  margin: 22px 0 11px;
  color: #fff;
  text-align: center;
`;
const SubHeader = styled("p")`
  font-size: 22px;
  margin: 0 0 11px;
  color: #fff;
  text-align: center;
`;

const AppImg = styled(GatsbyImageWrapper)`
  width: 100%;
`;

function OnlyOnDeviceLayout({ text }) {
  const data = useStaticQuery(graphql`
    {
      directus {
        page_mobile_only {
          image {
            filename_disk
          }
        }
      }
    }
  `);

  const { directus } = data;
  return (
    <>
      <SEO noindex={true} />
      <Flex justifyContent="center" backgroundColor="#52209f" width="100%" height="calc(100vh - 88px)">
        <Flex
          width="100%"
          maxWidth="380px"
          mx="auto"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <AppImg image={directus.page_mobile_only.image} />

          <Header>
            <Translate path={"directus.page_mobile_only.title"} />
          </Header>

          <SubHeader>{text}</SubHeader>
        </Flex>
      </Flex>
    </>
  );
}

export default OnlyOnDeviceLayout;
